<template>
  <!--
    Used to edit the theme-elements that are used in the videoPostprocessing.
  -->
  <Portlet
    :title="portletTilte"
    icon="tv"
    class="userDepenedentImageEdit"
  >
    <template
      slot="buttons"
    >
      <toggle-button
        v-model="previewOtherElements"
        :labels="{ checked: 'show other', unchecked: 'hide other' }"
        class="ml-3 mt-2"
        :width="120"
        :height="30"
        :font-size="12"
      />
    </template>
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="userDependentImageElement && themeId && elementId">
        <div
          class="el-table_1_column_1 is-left mb-3"
          colspan="2"
        >
          <div>
            <div class="cell row">
              <div class="col-sm-12 col-lg-8">
                <Preview
                  :element="userDependentImageElement"
                  :edit="true"
                  :theme-id="themeId"
                  :preview-other-elements="previewOtherElements"
                  :parent-errors="$validator.errors.items"
                  :image-url="imageUrl"
                  tag="rect"
                  @changeAttribute="changeAttributeNow"
                />
              </div>
              <div class="col-sm-12 col-lg-4">
                <h4 class="m-0">
                  {{ $t('userDependentImageEditComp.userDependentImageElement') }}
                </h4>
                <hr class="m-0 mb-3">

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('name') }}</label>
                  <div class="col-12 col-md-10">
                    <input
                      v-model="userDependentImageElement.name"
                      v-focus
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Name') }]"
                      type="text"
                    >
                    <span
                      v-show="errors.has('Name')"
                      class="badge badge-danger"
                    >{{ errors.first('Name') }}</span>
                  </div>
                </div>
                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.doScale') }}</label>
                  <div class="col-12 col-md-10">
                    <toggle-button
                      v-model="userDependentImageElement.doScale"
                      :width="50"
                      :height="25"
                      class="m-0"
                    />
                  </div>
                </div>

                <template v-if="userDependentImageElement.doScale">
                  <div
                    class="form-group row mb-4"
                  >
                    <label class="col-12 col-md-2">{{ $t('themeElementEdit.anchor') }}</label>
                    <div class="col-12 col-md-10">
                      <select
                        v-model="userDependentImageElement.anchor"
                        :class="['form-control', { 'is-invalid': this.$validator.errors.has('Anchor') }]"
                      >
                        <option
                          v-for="(key, index) in anchors"
                          :key="`cutConfigListItemAdd_Option${ index }`"
                          :value="key"
                        >
                          {{ key }}
                        </option>
                      </select>
                      <span
                        v-show="errors.has('Anchor')"
                        class="badge badge-danger"
                      >{{ errors.first('Anchor') }}</span>
                    </div>
                  </div>

                  <div
                    class="form-group row mb-4"
                  >
                    <label class="col-12 col-md-2">{{ $t('themeElementEdit.scaleMode') }}</label>
                    <div class="col-12 col-md-10">
                      <select
                        v-model="userDependentImageElement.scaleMode"
                        :class="['form-control', { 'is-invalid': this.$validator.errors.has('ScaleMode') }]"
                      >
                        <option
                          v-for="(key, index) in scaleModes"
                          :key="`cutConfigListItemAdd_Option${ index }`"
                          :value="key"
                        >
                          {{ key }}
                        </option>
                      </select>
                      <span
                        v-show="errors.has('ScaleMode')"
                        class="badge badge-danger"
                      >{{ errors.first('ScaleMode') }}</span>
                    </div>
                  </div>
                </template>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.flag') }}</label>
                  <div class="col-12 col-md-10">
                    <toggle-button
                      v-model="userDependentImageElement.isFlagCollection"
                      :width="50"
                      :height="25"
                      class="m-0"
                    />
                  </div>
                </div>

                <template v-if="userDependentImageElement.isFlagCollection">
                  <div
                    class="form-group row mb-4"
                  >
                    <label class="col-12 col-md-2">{{ $t('themeElementEdit.flagCollection') }}</label>
                    <div class="col-12 col-md-10">
                      <select
                        v-model="userDependentImageElement.flagCollection"
                        :class="['form-control', { 'is-invalid': this.$validator.errors.has('FlagCollection') }]"
                      >
                        <option
                          :value="null"
                          disabled
                        >
                          {{ $t('pleaseSelectOne') }}
                        </option>
                        <option
                          v-for="(collection, index) in flagCollections"
                          :key="`flagColection${ index }`"
                          :value="collection.name"
                        >
                          {{ collection.name }}
                        </option>
                      </select>
                      <span
                        v-show="errors.has('FlagCollection')"
                        class="badge badge-danger"
                      >{{ errors.first('FlagCollection') }}</span>
                    </div>
                  </div>
                </template>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">Sort Number</label>
                  <div class="col-12 col-md-10">
                    <input
                      v-model="userDependentImageElement.renderSortNumber"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('RenderSortNumber') }]"
                      type="number"
                    >
                    <span
                      v-show="errors.has('RenderSortNumber')"
                      class="badge badge-danger"
                    >{{ errors.first('RenderSortNumber') }}</span>
                  </div>
                </div>

                <template>
                  <h4 class="m-0 mt-4">
                    {{ $t('start') }}
                  </h4>
                  <hr class="m-0 mb-3">
                </template>
                
                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.eventKey') }}</label>
                  <div class="col-12 col-md-10">
                    <select
                      v-model="userDependentImageElement.startEventKey"
                      :class="['form-control', { 'is-invalid': this.$validator.errors.has('StartEventKey') }]"
                    >
                      <option
                        v-for="(key, index) in startModifiedEventKeys"
                        :key="`cutConfigListItemAdd_Option${ index }`"
                        :value="key"
                      >
                        {{ key }}
                      </option>
                    </select>
                    <span
                      v-show="errors.has('StartEventKey')"
                      class="badge badge-danger"
                    >{{ errors.first('StartEventKey') }}</span>
                  </div>
                </div>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.eventReporter') }}</label>
                  <div class="col-12 col-md-10">
                    <select
                      v-model="userDependentImageElement.startEventReporter"
                      :class="['form-control', { 'is-invalid': this.$validator.errors.has('StartEventReporter') }]"
                      @change="setBackStartEventReporterIndex(); setStartEventKeyListValues($event)"
                    >
                      <option
                        v-for="(key, index) in eventReporters"
                        :key="`cutConfigListItemAdd_Option2${ index }`"
                        :value="key"
                      >
                        {{ key }}
                      </option>
                    </select>
                    <span
                      v-show="errors.has('StartEventReporter')"
                      class="badge badge-danger"
                    >{{ errors.first('StartEventReporter') }}</span>
                  </div>
                </div>
                <div
                  v-if="userDependentImageElement.startEventReporter == 'VideoRecorder'"
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.eventReporterIndex') }} </label>
                  <div class="col-12 col-md-10">
                    <input
                      v-model="userDependentImageElement.startEventReporterIndex"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('StartEventIndex') }]"
                      type="number"
                    >
                    <span
                      v-show="errors.has('StartEventIndex')"
                      class="badge badge-danger"
                    >{{ errors.first('StartEventIndex') }}</span>
                  </div>
                </div>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.offset') }} <small>({{ $t('seconds') }})</small></label>
                  <div class="col-12 col-md-10">
                    <input
                      v-model="userDependentImageElement.startOffset"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('StartOffset') }]"
                      type="number"
                    >
                    <span
                      v-show="errors.has('StartOffset')"
                      class="badge badge-danger"
                    >{{ errors.first('StartOffset') }}</span>
                  </div>
                </div>

                <template>
                  <h4 class="m-0 mt-4">
                    {{ $t('end') }}
                  </h4>
                  <hr class="m-0 mb-3">
                </template>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.eventKey') }}</label>
                  <div class="col-12 col-md-10">
                    <select
                      v-model="userDependentImageElement.endEventKey"
                      :class="['form-control', { 'is-invalid': this.$validator.errors.has('EndEventKey') }]"
                    >
                      <option
                        v-for="(key, index) in endModifiedEventKeys"
                        :key="`cutConfigListItemAdd_Option2${ index }`"
                        :value="key"
                      >
                        {{ key }}
                      </option>
                    </select>
                    <span
                      v-show="errors.has('EndEventKey')"
                      class="badge badge-danger"
                    >{{ errors.first('EndEventKey') }}</span>
                  </div>
                </div>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.eventReporter') }}</label>
                  <div class="col-12 col-md-10">
                    <select
                      v-model="userDependentImageElement.endEventReporter"
                      :class="['form-control', { 'is-invalid': this.$validator.errors.has('EndEventReporter') }]"
                      @change="setBackEndEventReporterIndex(); setEndEventKeyListValues($event)"
                    >
                      <option
                        v-for="(key, index) in eventReporters"
                        :key="`cutConfigListItemAdd_Option2${ index }`"
                        :value="key"
                      >
                        {{ key }}
                      </option>
                    </select>
                    <span
                      v-show="errors.has('EndEventReporter')"
                      class="badge badge-danger"
                    >{{ errors.first('EndEventReporter') }}</span>
                  </div>
                </div>
                <div
                  v-if="userDependentImageElement.endEventReporter == 'VideoRecorder'"
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.eventReporterIndex') }} </label>
                  <div class="col-12 col-md-10">
                    <input
                      v-model="userDependentImageElement.endEventReporterIndex"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('EndEventIndex') }]"
                      type="number"
                    >
                    <span
                      v-show="errors.has('EndEventIndex')"
                      class="badge badge-danger"
                    >{{ errors.first('EndEventIndex') }}</span>
                  </div>
                </div>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('offset') }} <small>({{ $t('seconds') }})</small></label>
                  <div class="col-12 col-md-10">
                    <input
                      v-model="userDependentImageElement.endOffset"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('EndOffset') }]"
                      type="number"
                    >
                    <span
                      v-show="errors.has('EndOffset')"
                      class="badge badge-danger"
                    >{{ errors.first('EndOffset') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ThemeImageUploader
            v-if="(layoutImageId || userDependentImageId) && !userDependentImageElement.isFlagCollection"
            :layout-image-id="layoutImageId"
            :user-dependent-image-id="userDependentImageId"
            :file-is-available="userDependentImageElement.fileIsAvailable"
            @imageUrlUpdated="setImageUrl"
          />
        </div>
        <button
          class="btn btn-sm btn-secondary float-left"
          @click="cancel()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="times"
          />{{ $t('cancel') }}
        </button>
        <button
          class="btn btn-sm btn-primary float-right"
          @click="update()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="check"
          />{{ $t('save') }}
        </button>
        <div class="clearfix" />
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </Portlet>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "UserDependentImageEdit",
  components: {
    Preview: () => import('@/components/VideoPostprocessing/Preview.vue'),
    ThemeImageUploader: () => import('@/components/VideoPostprocessing/Theme/ThemeImageUploader.vue')
  },
  mixins: [
    errorMixin
  ],
  props: {
    themeId: {
      type: String,
      required: true
    },
    elementId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      userDependentImageElement: null,
      eventKeys: null,
      eventReporters: null,
      imageUrl: null,
      anchors: null,
      scaleModes: null,
      flagCollections: null,
      previewOtherElements: true,
      collectionType: 'Flags',
      startFinishEventKeys: [
        'SessionStarted', 
        'SessionFinished', 
        'RaceStarted', 
        'RaceFinished', 
        'RaceAborted'
      ],
      startModifiedEventKeys: null,
      endModifiedEventKeys: null
    }
  },
  computed: {
    portletTilte () {
      if (this.theme == null) {
        return this.$t('themeElementEdit.themeElementEdit');
      }
      if (this.theme.name == null) {
        return this.$t('themeElementEdit.themeElementEdit');
      }
      return `${ this.$t('themeElementEdit.themeElementEdit') }: ${ this.theme.name }`;
    },
    userDependentImageId (){
      if (this.$route.fullPath.includes("userdependentimage")) {
          return this.elementId;
      }
      return null;
    },
    layoutImageId (){
      if (this.$route.fullPath.includes("layoutimage")) {
        return this.elementId;
      }
      return null;
    }
  },
  created () {
    this.getDataWithLoading();
  },
  methods: {
    setImageUrl (url){
      this.imageUrl = url;
    },
    cancel () {
      this.$router.push(`/videopostprocessing/theme/${ this.themeId }/userdependentimageelement`);
    },
    getData () {
      this.axios.all([
        this.axios.get(`/VideoPostProcessing/GetUserDependentImageElement?userDependentImageElementId=${ this.elementId }`),
        this.axios.get(`/VideoPostprocessing/GetEventKeys`),
        this.axios.get(`/VideoPostprocessing/GetEventReporters`),
        this.axios.get(`/VideoPostprocessing/GetImageAnchors`),
        this.axios.get(`/VideoPostprocessing/GetImageScaleModes`),
        this.axios.get(`/VideoPostprocessing/GetAllImageCollections?collectionType=${ this.collectionType }`)
      ])
        .then(this.axios.spread((imageElementResponse, keyResponse, reportResponse, anchorResponse, scaleModeResponse, flagCollectionResponse) => {
          if (imageElementResponse == null) {
            return;
          }
          if (imageElementResponse.data == null) {
            return;
          }
          this.userDependentImageElement = imageElementResponse.data;

          if (keyResponse && keyResponse.data) {
            this.eventKeys = keyResponse.data;
          }
          if (reportResponse && reportResponse.data) {
            this.eventReporters = reportResponse.data;
          }
          if (anchorResponse && anchorResponse.data) {
            this.anchors = anchorResponse.data;
          }
          if (scaleModeResponse && scaleModeResponse.data) {
            this.scaleModes = scaleModeResponse.data;
          }
          if (flagCollectionResponse && flagCollectionResponse.data) {
            this.flagCollections = flagCollectionResponse.data;
          }

          var startEventReporterVal = this.userDependentImageElement.startEventReporter;
          var endEventReporterVal = this.userDependentImageElement.endEventReporter;

          if (startEventReporterVal === "StartFinish") {
            this.startModifiedEventKeys = this.eventKeys.filter(x => this.startFinishEventKeys.includes(x) && x != "Unknown");
          } else if (startEventReporterVal === "VideoRecorder") {
            this.startModifiedEventKeys = this.eventKeys.filter(x => !this.startFinishEventKeys.includes(x) && x != "Unknown");
          } else {
            this.startModifiedEventKeys = this.eventKeys.filter(x => x == "Unknown");
            this.startEventKey = "Unknown";
          }

          if (!this.startModifiedEventKeys.includes(this.userDependentImageElement.startEventKey)) {
            this.userDependentImageElement.startEventKey = this.startModifiedEventKeys.find(x => x);
          }

          if (endEventReporterVal === "StartFinish") {
            this.endModifiedEventKeys = this.eventKeys.filter(x => this.startFinishEventKeys.includes(x) && x != "Unknown");
          } else if (endEventReporterVal === "VideoRecorder") {
            this.endModifiedEventKeys = this.eventKeys.filter(x => !this.startFinishEventKeys.includes(x) && x != "Unknown");
          } else {
            this.endModifiedEventKeys = this.eventKeys.filter(x => x == "Unknown");
            this.endEventKey = "Unknown";
          }

          if (!this.endModifiedEventKeys.includes(this.userDependentImageElement.endEventKey)) {
            this.userDependentImageElement.endEventKey = this.endModifiedEventKeys.find(x => x);
          }
        }))
        .finally(() => {
          this.loading = false;
        });
    },
    getDataWithLoading () {
      this.loading = true;
      this.getData();
    },
    update () {
      this.axios.put(`/VideoPostProcessing/UpdateUserDependentImageElement`, this.userDependentImageElement)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          //this.getData(); //TODO: discuss if this is needed
          this.error_clear();
          this.$snotify.success(this.$t('userDependentTextEditComp.updateSuccess'));
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
          this.loading = false;
        });
    },
    deleteElement () {
      this.axios.delete(`/VideoPostProcessing/DeleteUserDependentImageElement?userDependentImageElementId=${ this.elementId }`)
        .then(() => {
          this.$snotify.success(this.$t('themeElementEdit.themeDeleteSuccess'));
          this.$router.push({path: `/videopostprocessing/theme/${ this.themeId }` });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    changeAttributeNow (value, attribute) {
      this.userDependentImageElement[attribute] = value;
    },
    setBackStartEventReporterIndex () {
      if(this.userDependentImageElement.startEventReporter != 'VideoRecorder')
      {
        this.userDependentImageElement.startEventReporterIndex = '0';
      }
      else if(this.userDependentImageElement.startEventReporterIndex === undefined)
      {
        this.userDependentImageElement.startEventReporterIndex = '1';
      }
    },
    setBackEndEventReporterIndex () {
      if(this.userDependentImageElement.endEventReporter != 'VideoRecorder')
      {
        this.userDependentImageElement.endEventReporterIndex = '0';
      }
      else if(this.userDependentImageElement.endEventReporterIndex === undefined)
      {
        this.userDependentImageElement.endEventReporterIndex = '1';
      }
    },
    setStartEventKeyListValues (event) {
      var val = event.target.value;

      if (val === "StartFinish") {
          this.startModifiedEventKeys = this.eventKeys.filter(x => this.startFinishEventKeys.includes(x) && x != "Unknown");
      } else if (val === "VideoRecorder") {
          this.startModifiedEventKeys = this.eventKeys.filter(x => !this.startFinishEventKeys.includes(x) && x != "Unknown");
      } else {
          this.startModifiedEventKeys = this.eventKeys.filter(x => x == "Unknown");
      }

      this.userDependentImageElement.startEventKey = this.startModifiedEventKeys.find(x => x);
    },
    setEndEventKeyListValues (event) {
      var val = event.target.value;

      if (val === "StartFinish") {
          this.endModifiedEventKeys = this.eventKeys.filter(x => this.startFinishEventKeys.includes(x) && x != "Unknown");
      } else if (val === "VideoRecorder") {
          this.endModifiedEventKeys = this.eventKeys.filter(x => !this.startFinishEventKeys.includes(x) && x != "Unknown");
      } else {
          this.endModifiedEventKeys = this.eventKeys.filter(x => x == "Unknown");
      }

      this.userDependentImageElement.endEventKey = this.endModifiedEventKeys.find(x => x);
    }
  }
}
</script>
